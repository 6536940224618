.container {
  padding: 10px 15px;
  background: linear-gradient(to bottom left, #151A1F 0%, #222933 100%);
}

.header {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  .jp {
    font-size: small;
    white-space: nowrap;
    margin-left: 8px;
  }
  .title {
    border-bottom: none;
    white-space: nowrap;
    margin-right: 10px;
  }
  .selector {
    width: 100px;
  }
  .home {
    position: absolute;
    top: 5px;
    right: 15px;
    a {
      color: #fff;
    }
  }
}

.main {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #666;
  padding: 10px 5px 0;
}
