.button {
  border: none;
  padding: 8px 10px;
  box-sizing: border-box;
  background: rgb(77,77,77);
  background: linear-gradient(180deg, rgba(77,77,77,1) 0%, rgba(115,115,115,1) 100%, rgba(0,212,255,1) 100%);
  color: #fff;
  font-size: 100%;
  cursor: pointer;
  &.wide {
    padding-left: 45px;
    padding-right: 45px;
  }
  &:active {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
}
