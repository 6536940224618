.textbox {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px 12px;
  font-size: 100%;
  border-radius: 0;
}
