.fraction {
  display: inline-block;
  font-style: italic;
  .numerator {
    display: inline-block;
    margin-right: 0.3em;
    color: rgb(221, 200, 149);
  }
  .denominator {
    display: inline-block;
    font-size: small;
  }
}
