.main {
}

.footer {
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.dropContainer {
  margin: 15px 10px 10px;
}
