.icon {
  margin-right: 12px;
  height: 16px;
  width: 16px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  align-items: center;
  display: flex;
  margin-right: 15px;
}
