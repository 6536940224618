.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    width: 100vw;
    height: 100%;
  }
  &.hidden {
    display: none;
  }
  &.modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.window {
  padding-bottom: 0;
  background: rgb(15, 21, 26);
  display: block;
  width: 100%;
}

.modal > .window {
  z-index: 19999;
  width: 90%;
  max-height: 92vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .body {
    width: 100%;
    margin: 0 -12px;
    padding: 13px 12px 0;
    overflow-y: auto;
    box-sizing: border-box;
  }
}

.title {
  position: relative;
  border-bottom: 1px solid rgba(220, 220, 220, 0.8);
  width: calc(100% + 24px);
  margin: -8px -12px 0;
  padding: 10px 20px;
  color: #fdfdfd;
  background-color: rgba(42, 49, 54, 0.9);
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeButton {
  width: 45px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
