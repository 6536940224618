.page {
  padding: 8px 10px;
}

.header {
}

.main {
  margin-top: 5px;
}
