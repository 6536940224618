$background: #090b0f;

.globalHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background:
    linear-gradient(90deg, $background, $background, rgba(0,0,0,0.5), rgba(0,0,0,0), rgba(0,0,0,0.7)),
    url("../../../../../static/images/mosel.png") no-repeat right/50%;
  .logo {
    height: 32px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .sitename {
    position: relative;
    top: 6px;
    display: flex;
    flex-direction: row;
  }
  .icons {
    margin-right: 5px;
  }
  a {
    color: #fdfdfd !important;
  }
}
