@keyframes blink {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}

.skill {
  width: 140px;
  height: 65px;
  font-size: small;
  position: relative;
  z-index: 1000;
  box-sizing: border-box;
  &:not(.empty) {
    border: 1px solid #aaa;
  }
  opacity: 0.2;
  &.active {
    animation: blink 2s linear 0s infinite;
  }
  &.on {
    opacity: 1;
  }
  &.canToggle {
    cursor: pointer;
  }
  padding: 5px 10px;
  .icon {
    float: left;
    height: 80px;
  }

  .main {
    line-height: 125%;
    position: relative;
  }

  .name {
    display: inline;
  }

  .additionalInfo {
    float: right;
    position: relative;
    bottom: -1px;
    right: 0;
    white-space: nowrap;
    font-size: xx-small;
  }

  .target {
    display: inline;
    padding: 1px 6px;
    margin-right: 2px;
    position: relative;
    color: #fff;
    font-weight: bold;
    font-size: x-small;
    &:before {
      content: "";
      width: 1.3em;
      height: 1.3em;
      transform: rotate(45deg);
      background-color: orange;
      position: absolute;
      z-index: -1;
      top: 2px;
      left: 0.3em;
    }
  }

  .only {
    margin-top: 1px;
    margin-left: 10px;
    position: relative;
    .limit {
      display: inline-block;
      position: absolute;
      top: -3px;
      left: -8px;
      border-radius: 10px;
      background-color: #899;;
      color: #fff;
      padding: 1px 5px;
    }
    vertical-align: middle;
    white-space: nowrap;
    display: inline;
    font-size: xx-small;
    background-color: #555;
    color: #aaa;
    padding: 0px 8px 0 13px;
  }

  .jp {
    position: absolute;
    bottom: 3px;
    left: 10px;
    font-size: x-small;
    color: #aaa;
    font-style: italic;
    font-weight: bold;
    .jpPoint {
      font-size: 110%;
    }
  }

  .type {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: xx-small;
    font-weight: bold;
    width: 60px;
    text-align: center;
    &.passive {
      background-color: #517EC0;
    }
    &.active {
      background-color: #59A73B;
    }
  }
}

.container {
  position: relative;
}
.background {
  background: #22272b;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
