$active-border-shadow: #69aada;

.box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
  font-size: 92%;
  color: #e0e0e0;
  background-color: rgba(42, 49, 54, 0.9);
  border: 1px solid rgba(220, 220, 220, 0.8);
  &.active {
    border: 1px solid lighten($active-border-shadow, 20%);
    box-shadow: inset 0 0 3px $active-border-shadow, 0 0 10px $active-border-shadow;
  }
}
