.layoutContainer {
  display: flex;
  flex-direction: row;

  & > * {
    flex: 1;
    padding: 5px;
  }
}

.chapter {
  position: absolute;
  top: 0;
  left: 0;
}

.cp {
  margin-left: 5px;
  margin-bottom: 5px;
}

.levels {
  font-size: large;
}

.categories {
  font-size: 80%;
}

.section {
  margin-bottom: 10px;
}

.lv {
  color: #aaa;
}

.windowFooter {
  padding: 10px 0;
  a {
    font-weight: normal;
  }
  .angle {
    display: inline-block;
    margin-right: 4px;
  }
}
