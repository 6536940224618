.folder {
  cursor: pointer;
  margin-bottom: 2px;
}

.content {
  margin-top: 2px;
  margin-left: 20px;
  &.hidden {
    display: none;
  }
}

.arrow {
  margin-right: 10px;
}
