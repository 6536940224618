.x {
	display: inline-block;
	width: 50px;
	height: 50px;
	position: relative;
	cursor: pointer;
  span::before, span::after {
  	display: block;
  	content: "";
  	position: absolute;
  	top: 50%;
  	left: 50%;
  	width: 100%;
  	height: 5%;
  	margin: -8% 0 0 -42%;
  	background: #e0e0e0;
  }
  span::before {
  	transform: rotate(-45deg);
  }
  span::after {
  	transform: rotate(45deg);
  }
}
