@import "../../../variables.scss";

.checkboxContainer {
  display: inline-block;
  .checkbox {
    display: inline-block;
    border: 1px solid rgba(220, 220, 220, 0.5);
    width: 15px;
    height: 15px;
    vertical-align: middle;
    transform: rotate(45deg);
    .checkIcon {
      visibility: hidden;
      transform: rotate(-45deg);
      position: relative;
      left: 2px;
      top: -5px;
      color: #ccc;
      font-size: small;
    }
    &.checked {
      border: 1px solid lighten($active-border-shadow, 20%);
      box-shadow: inset 0 5px 2px -5px #69aada, inset 0 -5px 3px -5px #69aada, 0 0 4px #69aada;
      .checkIcon {
        visibility: visible !important;
      }
    }
    input[type="checkbox"] {
      display: none;
    }
  }
}
