@import "../../../variables.scss";

.container {
  min-width: 2rem;
}

.checkButton {
  display: inline-block;
  font-size: x-small;
  padding: 2px 5px;
  position: relative;
  border: 1px solid rgba(220, 220, 220, 0.5);
  border-left: none !important;
  border-right: none !important;
  cursor: pointer;
  background-color: rgba(42, 49, 54, 0.9);
  margin: 0 6px;
  width: 90%;
  text-align: center;
  &.active {
    border: 1px solid lighten($active-border-shadow, 20%);
    box-shadow: inset 0 5px 1px -5px #69aada, inset 0 -5px 1px -5px #69aada, 0 0 4px rgba(105, 170, 218, 0.6);
    .left, .right {
      border: 2px solid lighten($active-border-shadow, 20%);
    }
  }
}

.left, .right {
  border: 1.5px solid rgba(220, 220, 220, 0.6);
  border-radius: 1px;
  width: 11px;
  height: 11px;
  transform: rotate(45deg) skew(15deg, 15deg);
  position: absolute;
  top: 3.5px;
}
.left {
  left: -6.5px;
  border-right: none !important;
  border-top: none !important;
  background: linear-gradient(to top right, rgba(42, 49, 54, 0.9) 0%, rgba(42, 49, 54, 0.9) 50%, transparent 50%, transparent 100%);
}
.right {
  right: -6px;
  border-left: none !important;
  border-bottom: none !important;
  background: linear-gradient(to bottom left, rgba(42, 49, 54, 0.9) 0%, rgba(42, 49, 54, 0.9) 50%, transparent 50%, transparent 100%);
}
