.window {
  background: #fff;
  color: #333;
  border: none;
}

.github {
  height: 1.5rem;
  vertical-align: middle;
}
