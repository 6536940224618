html, body {
  background-color: #090b0f;
  color: #fdfdfd;
  margin: 0;
  font-family: 'M PLUS 1p', 'Noto Sans JP', sans-serif;
  height: 100%;
}

a {
  text-decoration: none;
  font-weight: bold;
  &:link {
    color: #F25F5C;
  }
  &:visited {
    color: #F25F5C;
  }
  &:hover {
    color: #FF7B78;
  }
}
